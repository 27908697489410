import React from 'react';

const About = () => {    
    return (
        <div>
            <h3 className='text-center'>Page Under Constraction</h3>
        </div>
    );
};

export default About;